jQuery.extend({

    viewportWidth: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder              	   : _this,
            viewportWidth				: null,

            // PRIVATE FUNCTIONS
            setup:function()
            {
                _private.holder = $(holder);
            },
            setViewportWidth:function()
            {
	            if($(window).width() <= 320)
	            {
		            _private.viewportWidth = "xxs";
	            }else if($(window).width() <= 640)
	            {
		            _private.viewportWidth = "xs";
	            }else if($(window).width() <= 768)
	            {
		            _private.viewportWidth = "sm";
	            }else if($(window).width() <= 1024)
	            {
		            _private.viewportWidth = "md";
	            }else{
		            _private.viewportWidth = "lg";
	            }
	            _private.holder.addClass("viewportWidth-"+_private.viewportWidth);
            },
            deleteViewportWidth:function()
            {
	            $($(_private.holder).attr("class").toString().split(' ')).each(function(_index, _ell)
	            {
		           if(_ell.indexOf("viewportWidth-") !== -1)
		           {
			       	_private.holder.removeClass(_ell);
		           }
	            });
            }
        };
        function initialize()
        {
            _private.setup();
            _private.setViewportWidth();
        }
        $(document).ready(function()
        {
            initialize();
        });
        $( window ).resize(function() {
	        _private.deleteViewportWidth();
			_private.setViewportWidth();
		});
    }
});
$(function() {
    $( document ).ready(function()
    {
            var viewportWidth = new $.viewportWidth($("body"));
    });
});
