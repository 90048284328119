jQuery.extend({

    navigation: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder              	   	: _this,
            holderList					: null,
            buttonToggle				: null,
            holderOutside				: null,

            // PRIVATE FUNCTIONS
            setup:function()
            {
                _private.holder = $(holder);
                _private.holderList = _private.holder.find(".m-navigation__list");
                _private.buttonToggle = _private.holder.find('.m-header-toggle__item');
                _private.holderOutside = $('.o-overlay');
                
                _private.buttonToggle.bind("click", _private.clickToggle);
                _private.holderOutside.bind("click", _private.clickOutside);
            },
            updateNavigation:function()
            {
	            if($('body').hasClass('viewportWidth-md') || $('body').hasClass('viewportWidth-sm') || $('body').hasClass('viewportWidth-xs') || $('body').hasClass('viewportWidth-xxs'))
	            {
		            if(_private.holderOutside.hasClass('opened'))
		            {
			            _private.closeNavigation();
		            }else{
			            _private.openNavigation();
		            }
	            }else{
		            _private.closeNavigation();
	            }
            },
            openNavigation:function()
            {
	            if(!_private.holderOutside.hasClass('opened'))
	            {
		            _private.holderOutside.addClass('opened');
	            }
	            if(!_private.holderList.hasClass('opened'))
	            {
		            _private.holderList.addClass('opened');
	            }
            },
            closeNavigation:function()
            {
	            if(_private.holderOutside.hasClass('opened'))
	            {
		            _private.holderOutside.removeClass('opened');
	            }
	            if(_private.holderList.hasClass('opened'))
	            {
		            _private.holderList.removeClass('opened');
	            }
            },
            clickToggle:function()
            {
				_private.updateNavigation();
            },
            clickOutside:function()
            {
				_private.closeNavigation();
            }
        };
        function initialize()
        {
            _private.setup();
        }
        $(document).ready(function()
        {
            initialize();
        });
    }
});
$(function() {
    $( document ).ready(function()
    {
            var navigation = new $.navigation($(".m-navigation"));
    });
});
