jQuery.extend({

    accordion: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder              	   	: _this,
            items						: null,

            // PRIVATE FUNCTIONS
            setup:function()
            {
	            _private.holder = $(holder);
	            _private.items = $(_private.holder).find(".accordion-item");
	            
	            $(_private.items).each(function(_index, _ell)
	            {
		            $(_ell).find(".accordion-item--header").bind("click", _private.clickListener);
		            $(_ell).find(".accordion-item--content").bind("click", _private.clickListener);
		            $(_ell).find(".accordion-item--content").toggle();
	            });
            },
            clickListener:function(event)
            {
	            $(event.target)
	            	.closest(".accordion-item")
	            	.toggleClass("opened")
	            	.find(".accordion-item--content")
	            	.slideToggle();
            }
        };
        function initialize()
        {
            _private.setup();
        }
        $(document).ready(function()
        {
            initialize();
        });
    }
});
$(function() {
    $( document ).ready(function()
    {
	    var accordion = new $.accordion($(".accordion"));
    });
});
