Number.prototype.formatNumberToEuro = function(value, _d, _t){
	var n = this; 
    var c = isNaN(value = Math.abs(value)) ? 2 : value; 
    var d = _d == undefined ? "," : _d; 
    var t = _t == undefined ? "." : _t; 
    var s = n < 0 ? "-" : ""; 
    var i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))); 
    var j = (j = i.length) > 3 ? j % 3 : 0;
    
	return "€ " +  s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
 };