$.fn.restrictNumeric = function()
{
	this.bind("input", function()
	{
		if(this.value)
		{
			// restrict to numbers, no decimals
			this.value = this.value.replace(/[^0-9]/g,'');
		}
	});
};