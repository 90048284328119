jQuery.extend({

    modal: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder              	   				: _this,
            buttonClose								: null,

            // PRIVATE FUNCTIONS
            setup:function()
            {
	            _private.holder 					= $(holder);
	            _private.buttonClose				= _private.holder.find(".icon-cross");
	            
	            var _modalName						= _private.holder.attr("id");
	            
	            $("a[modal='"+_modalName+"']").bind("click", _private.show);
	            _private.holder.find(".modal--overlay").bind("click", _private.hide);
	            _private.buttonClose.bind("click", _private.hide);
            },
            show:function()
            {
	            _private.holder.show();
            },
            hide:function()
            {
	            _private.holder.hide();
            }      
        };
        function initialize()
        {
	        _private.setup();
        }
        $(document).ready(function()
        {
            initialize();
        });
    }
});
$(function() {
    $( document ).ready(function()
    {
	    var modalSignup = new $.modal($("#modal-signup"));
    });
});
